import React, { useMemo, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Picker from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import { RenderIf, Button, FileSlider, GetMetaData } from "shared/components";
import { useEditDiscussionMutation } from "redux/api/discussion";
import { useDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { useGetInterestsQuery } from "redux/api/interestApi";
import Member from "shared/media/user/userIcon.png";
import { groupOptionsByAlphabet } from "shared/helper/updateObject";
import "./PostCard.scss";

const EditPostCard = ({
  disType,
  siteUserID,
  postID,
  inputVal,
  setInputVal,
  interests,
  filer,
  show,
  close,
  refetch,
  setDiscussionFile,
}) => {
  const { REACT_APP_BASE_PHOTO_URL, REACT_APP_BASE_PHOTO_URL_MAIN } =
    process.env;
  const { t } = useTranslation();
  const { firstName, lastName, id, position } = useSelector(
    (state) => state.user?.user
  );
  const pickerRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [link, setLink] = useState("");
  const [gotFileFromFileComp, setGotFileFromFileComp] = React.useState();
  const [getDeletedIds, setDeletedIds] = React.useState();
  const [editDiscussion, { isSuccess: editSuccess, isLoading }] =
    useEditDiscussionMutation();
  const { data } = useDetailedInfoByIdQuery({ id: id });
  const [showPicker, setShowPicker] = useState(false);
  const [interestOption, setInterestOption] = useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = useState([]);
  const { data: interestedData, isSuccess: interestSuccess } =
    useGetInterestsQuery();
  const handleChangeInterest = (selectedOptions) => {
    const selectedIds = selectedOptions?.map((option) => option.value);
    setSelectedOptionIds(selectedIds);
    setInterestOption(selectedOptions);
  };
  const onEmojiClick = (emojiObject) => {
    const emoji = emojiObject.emoji;
    const textarea = document.getElementById("text");
    const { selectionStart, selectionEnd } = textarea;
    const currentValue = textarea.value;
    const newValue =
      currentValue.slice(0, selectionStart) +
      emoji +
      currentValue.slice(selectionEnd);
    setInputVal(newValue);

    requestAnimationFrame(() => {
      textarea.selectionStart = textarea.selectionEnd =
        selectionStart + emoji.length;
      textarea.focus();
    });
  };

  const newFiles = useMemo(
    () =>
      filer?.map((file) => {
        return {
          ...file,
          fileUrl: `${REACT_APP_BASE_PHOTO_URL_MAIN}${file?.fileUrl}`,
        };
      }),
    [filer]
  );

  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/\n/g, "\r\n");
    setInputVal(newValue);
  };

  const getImagesFromFile = (files) => setGotFileFromFileComp(files);
  const getImagesIds = (files) => setDeletedIds(files);
  const onClick = () => setLink("");

  const condition = inputVal?.trim() === "" && !gotFileFromFileComp;
  const handleSubmitPut = () => {
    const formData = new FormData();
    const missingIds = interests
      ?.filter((item) => !selectedOptionIds.includes(item.id))
      .map((item) => item?.id);
    const newItem = selectedOptionIds?.filter(
      (id) => !interests.some((item) => item.id === id)
    );
    inputVal && formData.append("Text", inputVal);

    formData.append("Top", true);
    formData.append("SiteUserId", siteUserID);
    formData.append("DiscussionType", 1);
    formData.append("Id", postID);

    let count = 0;
    if (newItem?.length) {
      for (let i = 0; i < newItem?.length; i++) {
        formData.append("Interests", newItem?.[i]);
      }
    }
    if (missingIds?.length) {
      for (let i = 0; i < missingIds?.length; i++) {
        formData.append("DeletedInterests", missingIds?.[i]);
      }
    }
    if (getDeletedIds?.length) {
      for (let i = 0; i < getDeletedIds?.length; i++) {
        formData.append("DeletedFiles", getDeletedIds[i]);
      }
    }
    for (let i = 0; i < gotFileFromFileComp?.length; i++) {
      const currentFile = gotFileFromFileComp?.[i];

      if (!currentFile?.id) {
        count = 1;
        formData.append(`Files`, currentFile);
      }
    }

    if (count === 0) {
      formData.append("Files", null);
    }

    !condition && editDiscussion(formData);
  };
  useEffect(() => {
    if (editSuccess) {
      close();
      refetch();
      setShowPicker(false);
    }
  }, [editSuccess]);
  useEffect(() => {
    if (interests) {
      const selected = interests?.map((c) => ({
        value: c?.id,
        label: c?.name,
      }));
      setInterestOption(selected);
      setSelectedOptionIds(interests?.map((c) => c?.id));
    }
  }, [interests]);

  useEffect(() => {
    if (interestSuccess) {
      const interestsOptions = interestedData?.data?.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.name,
      }));
      setOptions(interestsOptions);
    }
  }, [interestSuccess]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      show={show}
      onHide={() => {
        close();
        setShowPicker(false);
      }}>
      <Modal.Header closeButton>
        <Modal.Title>{t("edit")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-visible">
        <div className="body-div">
          <div className="body-divTop d-flex">
            <div className="body-divTopImg">
              <img
                alt="user"
                src={
                  data?.profilePhotoUrl
                    ? `${REACT_APP_BASE_PHOTO_URL}${data?.profilePhotoUrl}`
                    : Member
                }
              />
            </div>
            <div className="body-divTopRight mx-2">
              <h4>
                {firstName} {lastName}
              </h4>
              <span>{position}</span>
            </div>
          </div>
          <div className="body-divBody">
            <textarea
              className="pt_8 emojiField"
              type="text"
              value={inputVal}
              rows="2"
              cols="30"
              id="text"
              name="text"
              onChange={handleInputChange}
              placeholder={t("whatTalkAbout")}
            />
            <div className="body-divBodyEmoji">
              <div
                className="body-divBodyEmojiIcon"
                onClick={() => setShowPicker((val) => !val)}>
                <InsertEmoticonIcon />
              </div>
              <RenderIf condition={showPicker}>
                <div ref={pickerRef}>
                  <Picker className="emojiPicker" onEmojiClick={onEmojiClick} />
                </div>
              </RenderIf>
            </div>
            <RenderIf condition={link !== ""}>
              <GetMetaData url={link} closeHandler={onClick} />
            </RenderIf>
          </div>
          <div className="w-100 mb-3">
            <Select
              closeMenuOnSelect={false}
              placeholder={t("tags")}
              onChange={handleChangeInterest}
              value={interestOption}
              isMulti
              classNamePrefix="custom-react-select"
              isSearchable
              options={groupOptionsByAlphabet(
                options?.filter(
                  (option) =>
                    !interests?.some((selected) => selected.id === option.value)
                )
              )}
            />
          </div>
          <div id="filepond-container">
            <FileSlider
              file={newFiles}
              multiple={true}
              forDiscussion
              handleImageID={getImagesIds}
              handleImageFile={getImagesFromFile}
              setDiscussionFile={setDiscussionFile}
              discussionFile={filer}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="postCardTwoRight d-flex align-items-center">
          <Button
            disabled={isLoading || (!inputVal?.trim() && filer?.length === 0)}
            onClick={handleSubmitPut}
            className="pt_10_32"
            background="#057953">
            {t("edit")}
            <RenderIf condition={isLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPostCard;
